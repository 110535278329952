<template>
  <div>
    <div class="layoutBorder">
      <div class="layoutHeader">
        <h4>{{ titleH }}</h4>
      </div>
      <div class="layoutBody">
        <div>
          <baseinput :label="titleInput" v-mask="'#########'" v-model="demand" @change="changeDemand()"
            :errMsg="errMsgDemand" type="tel">
          </baseinput>
          <baseinput placeholder="Введите сумму" :label="'Сумма платежа'" v-mask="'#########'" v-if="!errMsgDemand && !loading" v-model="summ" :errMsg="errMsgSumm"
            type="tel">
          </baseinput>
        </div>
        <loader v-if="loading"></loader>
        <div v-else-if="!errMsgDemand && !errMsgSumm && summ > 0">
          <div>
            <ul>
              <li>ФИО: {{ dealObj.ClientFIO }}</li>
              <li>Дата платежа: {{ dealObj.CurDate | timeFromXtoDateView }}</li>
              <li>Платеж: {{ dealObj.CurPay | moneyIntlFormatNumber }}</li>
            </ul>
          </div>
          <button v-on:click="getFrame">Оплатить</button>
          <div v-if="showFrame">
            <component :dealObj="dealObj" class='body' id="psFrame"
              :is="'payment' + availablePaymentSystem.formParam.name">
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dlRouting",
  data() {
    return {
      loading: true,
      demand: 0,
      errMsgDemand: '',
      errMsgSumm: '',
      summ: '',
      maxSumm: 0,
      dealObj: {},
      paymentSystems: [],
      showFrame:false
    }
  },
  components: {
    baseinput: () => import('@/components/Form/element/baseinput'),
    loader: () => import('@/components/other/loader'),
    paymentZk: () => import('./paymentZk'),
    paymentQiwi: () => import('./paymentQiwi'),
    paymentTinkoff: () => import('./paymentTinkoff'),
    paymentZkLink: ()=>import('./paymentZkLink'),
  },
  computed: {
    mskOrNsb(){
      return parseInt(this.demand.toString()[0],10) >= 5 ? 'nsb' : 'msk';
    },
    titleInput(){
      if (this.mskOrNsb === 'nsb') {
        return 'Номер платежного id';
      } else {
        return 'Номер договора';
      }
    },
    titleH(){
      if (this.mskOrNsb === 'nsb') {
        return 'Оплата по платежному id';
      } else {
        return 'Оплата по номеру договора';
      }
    },
    availablePaymentSystem() {
      // if(parseInt(this.dealObj.dualDealSumm) >= 60000 && this.dealObj.Is_pdl == 0){
      //   return {"formId":666,"formName":"форма оплаты зол. корона","formParam":{"url":"","name":"ZkLink","available":"true"}}
      // }
      return this.paymentSystems.find(x => x.formParam.available === 'true')
      // return this.paymentSystems.find(x => x.formParam.name === 'Zk')
    },
  },
  watch: {
    summ: {
      async handler(newValue) {
        this.showFrame = false;
        if (newValue === '' || parseInt(newValue, 10) > 0) {
          this.dealObj.CurPay = newValue
          this.errMsgSumm = ''
        } else {
          this.errMsgSumm = 'Некорректная сумма';
        }
      }
    }
  },
  methods: {
    getFrame(){
      this.showFrame=true;
    },
    async getDlPaymentInfo() {
      this.loading = true
      try {
        return await this.$external.universalRequest({
          externalType: 'zita',
          action: 'getDealPaymentInfo',
          type: 'dlPayment',
          demand: this.demand,
        })
      } catch (e) {
        if (e.errorCode == 200) {
          this.errMsgDemand = e.errorMsg;
          return {}
        }
      } finally {
        this.loading = false
      }
    },
    async changeDemand() {
      this.dealObj = await this.getDlPaymentInfo() || {}
      if (this.dealObj.DlCode) {
        this.summ = this.dealObj.Is_pdl == 1 ? Math.ceil(this.dealObj.CurPay) : '';
        this.maxSumm =   this.summ
        this.errMsgDemand = ''
      }
    },
  },
  async mounted() {
    this.demand = this.$route.query.dl
    try {
      const { result } = await this.$myApi.singleRequest({
        action: 'paymentPages',
        type: 'Qiwi'
      })
      this.paymentSystems = result
      await this.changeDemand()
    } catch (e) {
      alert(e)
    }
  }
}
</script>

<style scoped>

</style>
